
import React, { Component ,useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};
const axios = require('axios')

const CheckoutForm = (amm) => {
  let ammonto = amm;
  console.log('quiiiiia ammm');
  console.log(amm);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    console.log('ammont nel cl');
    console.log(ammonto);
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      console.log(result.token);

      let pollo = stripeTokenHandler(result.token,ammonto);
      console.log('quiiiii');
      console.log(pollo);


    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div class="form-row">
        <label style={{fontSize:20,fontWeight:800}} for="card-element">
          Credit or debit card
        </label>
        <div className='cardsPay'>
          <CardElement
            id="card-element"
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
          <div className="card-errors" role="alert">{error}</div>
        </div>
      </div>
      <button type="submit" style={{background:'var(--color-primary)',marginBottom:20}} className='buttonAction'>Paga con la carta</button>

    </form>
  );
}

// Setup Stripe.js and the Elements provider pk_test_rFDgx5pPYhBrtBn6FsiZb6w1   pk_live_DPCPtTSpBw7vmEorQPZTurRC
const stripePromise = loadStripe('pk_test_rFDgx5pPYhBrtBn6FsiZb6w1');

const App = (ammont) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm amm={ammont} />
    </Elements>
  );
}

// POST the token ID to your backend.
async function stripeTokenHandler(token,amount) {

console.log('wuiiiii ammonto');
console.log(amount.amm.ammont);
console.log(Number(String(amount.amm.ammont).replace('.', '')) * 10);
let order =  await axios.post('https://api-procution-rivolta.herokuapp.com/api/v1/stripeorder', {
      amount: Number(String(amount.amm.ammont).replace('.', '')) * 10,
      source: token.id
    })


    console.log(order.data);
    //setReceiptUrl(order.data.charge.receipt_url)

  /*const response = await fetch('http://localhost:3034/api/v1/stripeorder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: token.id})
  });*/
  console.log('risposta');

  //console.log(response.json());
}

export default App
