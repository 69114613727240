import React from 'react';

//importa contex di auth
import AuthUserContext from './context';

//boh
import { withFirebase } from '../Firebase';


const withAuthentication = Component => {

  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      //console.log(props);
      //vede se c'è authUser nello storage
      //
      //console.log(JSON.parse(localStorage.getItem('authUser')));
      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }

    componentDidMount() {


      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {

          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {

          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        },
      );
    }

    componentWillUnmount() {

      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

//boh xke??
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
