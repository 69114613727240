export const LANDING = '/';
export const ORDER = '/order';
export const DELIVERY = '/delivery';
export const ADDPAGE = '/0/lavoro/new'
export const ADDPAGESINGOLA = '/0/lavoro/new/singola'
export const CLIENTI = '/clienti';
export const GRUPPI = '/categorie';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LAVORO = '/lavoro/:id';
export const USER = '/staff/:id';
export const ORDINI = '/ordini';
export const PRODOTTI = '/prodotti';
export const NEWPROD = '/newprod';
export const NEWCLIENTE = '/newcliente';
export const MATERIALE = '/materiale';
export const NEWMATERIALE = '/newmateriale';
export const MODIFICAMATERIALE = '/modificamateriale';
export const MODIFICAPRODOTTO = '/modificaprodotto';
export const MODIFICACLIENTE = '/modificacliente';
export const VENDITORE = '/venditore';
export const VENDITOREORDINE = '/venditoreordine/:id';
export const PDF = '/pdf';
export const CHECKOUT = '/checkout/payment';
export const MENU = '/menu';
export const PRIVACY = '/privacy'
