import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import {getTimeStops} from '../../util/functions';
import API from "../../util/Api.js"


class Prenota extends Component {

  constructor(props) {
    super(props);
    this.state = {
          prenotazione:{},
          visible:false,
          data:null,
          open:false,
          option:[],
          optionPersone:[{value:1,label:'1'},{value:2,label:'2'},{value:3,label:'3'},{value:4,label:'4'},{value:6,label:'6'}],
    };


  }


  Prenotatavolo(){
    let {prenotazione} = this.state;

    if(prenotazione && prenotazione.fasciaOraria && prenotazione.npersone){
      const ApiPrenota= new API ({url:'/prenotazione'});
    	var entity= {name:"prenotazione"};
    	ApiPrenota.createEntity(entity);

      var ppp = {};

      let obj = {
        "idTelegram":this.props.data.idTelegram,
    		"status" : 'new',
        "clienteNome":this.props.auth.nome,
    		"cliente" :this.props.auth.id,
    		"rifRistor":this.props.data._id,
    		"fasciaOraria": prenotazione ? prenotazione.fasciaOraria :null,
        'npersone':prenotazione ? prenotazione.npersone : null,
        'giorno': new Date().getTime(),
        'note':'',
    	 }

       console.log(obj);

    	ApiPrenota.endpoints.prenotazione
    	.create(obj,ppp)
    	.then(res=>{
        this.setState({prenotazione:res.data,visible:true})
    	});
    }else {
      alert('devi compilare entrambi i campi obbligatori!')
    }



  }


  todayIstest(){
    let {data} = this.state;
    if(data){
      let orari_daily_p = data.orari_daily_p;

      var weekday = new Array(7);
      weekday[0] = "domenica";
      weekday[1] = "lunedì";
      weekday[2] = "martedì";
      weekday[3] = "mercoledì";
      weekday[4] = "giovedì";
      weekday[5] = "venerdì";
      weekday[6] = "sabato";
      let today = new Date()
      let numeberDay = weekday[today.getDay()];
      let orariDays = orari_daily_p ? orari_daily_p[numeberDay] : [];
      console.log('orarari dayyy');
      console.log(orariDays);
      let arrayYoUse = [];



      if(orariDays && orariDays['M']){
        console.log('facci M');
        arrayYoUse =  arrayYoUse.concat(getTimeStops(orariDays['M'],data))
      }
      if(orariDays && orariDays['P']){
        console.log('facci P');
        arrayYoUse = arrayYoUse.concat(getTimeStops(orariDays['P'],data))
      }

      this.setState({option:arrayYoUse})

    }
  }

  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.props.data !== propsPrecedenti.data) {
      this.setState({data:this.props.data},()=>{
        this.todayIstest();
      })
    }
  }

  componentDidMount() {


  }


addToPrenotazione(x,type){
  console.log(x.value);
  let {prenotazione} = this.state;
  if(prenotazione){
    prenotazione[type] = x.value;
  }else {

  }

  this.setState({prenotazione})

}



render() {
    let {visible,data,option,optionPersone,prenotazione} = this.state;
    if(data && data.daily_p){
      return (
        <div>
          <Modal open={this.state.open}  effect="fadeInUp" onClose={() => this.setState({open:false,prenotazione:null,visible:false})}>
            {visible ? (
              <div style={{minWidth:300}}>
                <h2>Hai effettuato la richiesta di prenotazione</h2>
                <p>{prenotazione ? prenotazione.npersone : null} ore {prenotazione ? prenotazione.fasciaOraria : null}</p>
                <p>verrai contattato per la conferma al più presto!</p>
                <p>per controllare lo stato della tua richiesta vai pure sul tuo menu</p>

              </div>
            ) : (
              <div style={{minWidth:300}}>
                <h2>Prenota un tavolo</h2>
                  <div>
      							<label style={{marginBottom:10}}>In che fascia?</label>
                      <div   style={{marginTop:20}}>
                        <Select
                          onChange={(x)=>this.addToPrenotazione(x,'fasciaOraria')}
                          className="basic-single"
                          classNamePrefix="select"
                          name="fasciaOraria"
                          options={option}
                        />
                      </div>
                      <br></br>
                    <label style={{marginBottom:10}}>Numero persone</label>
                      <div style={{marginTop:20}}>
                        <Select
                          onChange={(x)=>this.addToPrenotazione(x,'npersone')}
                          className="basic-single"
                          classNamePrefix="select"
                          name="npersone"
                          options={optionPersone}
                        />
                      </div>
                      {prenotazione && prenotazione.fasciaOraria && prenotazione.npersone ? (
                        <div style={{padding:1,marginTop:30}} onClick={()=> this.Prenotatavolo()} className='buttonFigo' ><p  className='testoPrenotazione' style={{textAlign:'center'}}>Manda richiesta Prenotazione</p></div>

                      ) : (
                        <div style={{padding:1,marginTop:30,background:'#eee',color:'black',cursor:'not-allowed'}}  className='buttonFigo' ><p  className='testoPrenotazione' style={{textAlign:'center'}}>Manda richiesta Prenotazione</p></div>

                      )}
                      <p style={{fontSize:12}}>*La prenotazione verrà confermata su whatsapp sul numero lasciato all'iscrizione</p>
      						</div>
              </div>
            )}

          </Modal>
          {this.props.cassa ? (
            <div></div>
            /*<div style={{background:'transparent',display:'flex',textAlign:'center'}}>
              <div style={{width:'100%',borderRadius:0}} onClick={()=>this.props.auth ? this.setState({open:true}) :  this.props.history.push('/signin')} className='buttonFigo' ><p  className='testoPrenotazione' style={{textAlign:'center'}}>Prenota un tavolo</p></div>
          </div>*/
          ) : (
            <div className='containerFix' style={{background:'transparent',padding:10,display:'flex',marginTop:10,textAlign:'center'}}>
              <span  style={{fontWeight:300,fontSize:20,marginRight:20,padding:10,color:'var(--color-specialBlacl)'}}>Vuoi un tavolo per oggi ? </span><div onClick={()=>this.props.auth ? this.setState({open:true}) :  this.props.history.push('/signin')} className='buttonFigo' ><p  className='testoPrenotazione' style={{textAlign:'center'}}>Prenota</p></div>
            </div>
          )}

          {/*<div className='containerFix' style={{background:'transparent',padding:10,display:'flex',marginTop:10,textAlign:'center'}}>
            <span  style={{fontWeight:300,fontSize:20,marginRight:20,padding:10,color:'var(--color-specialBlacl)'}}>Vuoi un tavolo per oggi ? </span><div onClick={()=>this.props.auth ? this.setState({open:true}) :  this.props.history.push('/signin')} className='buttonFigo' ><p  className='testoPrenotazione' style={{textAlign:'center'}}>Prenota</p></div>
          </div>*/}
        </div>
      )
    }else {
      return null
    }

  }
}

//const condition = authUser => !!authUser;

export default Prenota
