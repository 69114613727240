import React from 'react';
//import logo from './logo.svg';
import './App.css';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';


class Benvenuto extends React.PureComponent<IExampleProps> {
  render() {
    return (
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    )
  }
}

export default Benvenuto;
