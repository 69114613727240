const axios = require('axios')
let urll = 'https://rivolta-api-server.herokuapp.com/api/v1'

 //urll = 'http://localhost:3034/api/v1'
 //urll = 'http://localhost:3001/api/v1'
 urll = 'https://api-procution-rivolta.herokuapp.com/api/v1'

class API {

  constructor(){

    //this.url = 'http://localhost:3001'
    this.url = urll
    this.endpoints = {}

  }
  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {

    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity)
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }
  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints( { name } ) {



    var endpoints = {}

    const resourceURL = `${this.url}/${name}`

    //endpoints.getAllQuery = ({ id }, config={}) => axios.get(`${resourceURL}?Azziende={all}${id}`, config)

    //endpoints.getAllQueryDip = ({ id }, config={}) => axios.get(`${resourceURL}?dipendente={all}${id}`, config)

    //endpoints.getByRif = ({ id }, config={}) => axios.get(`${resourceURL}?rif=${id}`, config)

    //endpoints.getAllOk = ( {config={}} ) => axios.get(`${resourceURL}`, Object.assign({ headers: config }))
    //
    //endpoints.getAllOk = ( config={} ) => console.log(config.config);

    
    //endpoints.getAllOk = (config={}) => axios.get(resourceURL, { headers: config})

    endpoints.getAllOk = (config={}) => axios.get(resourceURL)

    //endpoints.getAllParam = (params={},config={}) => axios.get(resourceURL, { params: params,headers: config})

    endpoints.getAllParam = (params={},config={}) => axios.get(resourceURL, { params: params})




    //endpoints.getAll = ({ query={}}, config={} ) => axios.get(resourceURL, Object.assign({ params: query , config }))

    //endpoints.getAllMulty = ({ id , idr}, config={}) =>  axios.get(`${resourceURL}/${id}/${idr}`, { headers: config})

    endpoints.getAllMulty = ({ id , idr}, config={}) =>  axios.get(`${resourceURL}/${id}/${idr}`)

    //endpoints.getOne = ({ id }, config={}) =>  axios.get(`${resourceURL}/${id}`, { headers: config})

    endpoints.getOne = ({ id }, config={}) =>  axios.get(`${resourceURL}/${id}`)

    //endpoints.create = (toCreate, config={}) =>  axios.post(resourceURL, toCreate, { headers: config})

    endpoints.create = (toCreate, config={}) =>  axios.post(resourceURL, toCreate)

    //endpoints.update = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config)

    endpoints.update = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate)

    //endpoints.updateNew = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.idDate}`, toUpdate, config)

    endpoints.updateNew = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.idDate}`, toUpdate)

    endpoints.patch  = ({id}, toPatch, config={}) => axios.patch(`${resourceURL}/${id}`, toPatch, config)

    //endpoints.delete = ({ id }, config={}) => axios.delete(`${resourceURL}/${id}`, { headers: config})
    endpoints.delete = ({ id }, config={}) => axios.delete(`${resourceURL}/${id}`)

    //console.log(endpoints);
    return endpoints

  }

}

export default API
