import React, { Component } from 'react';
import Facebook from './facebook.svg'
import Insta from './instagram.svg'
import Whats from './whatsapp.svg'
import Logo from '../img/logo.png'
import {Link} from "react-router-dom";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };


  }



  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):

  }

  componentDidMount() {

  }







render() {
    return (
      <div style={{width:'100%',maxWidth:1100,marginLeft:'auto',marginRight:'auto'}}>
        <div style={{padding:10,color:'white',fontSize:14}}>
        </div>
        <Link style={{color:'white'}} to='/privacy'>Privacy</Link>
        {/*<div style={{borderRadius:10,marginTop:20,marginBottom:20,background:'white',display:'inline-block',padding:10}} className="ccl-9aab795066526b4d">
          <img className='iconSocial' alt='social' src={Facebook} />
          <img className='iconSocial' alt='social' src={Insta} />
          <img className='iconSocial' alt='social'  src={Whats} />
        </div>*/}
        <div style={{textAlign:'center',padding:20}}><a href='https://rivolta.app'><img style={{width:200,marginTop:30}} src={Logo} /></a></div>

      </div>



    )
  }
}

//const condition = authUser => !!authUser;

export default Footer
