import React, {Component} from 'react';
import Logo from '../img/logoRis.png';
import {Link} from "react-router-dom";
import {subTotal} from '../../util/functions.js'
class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };

  }

  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):

  }

  componentDidMount() {
    var elem = document.getElementById("caricamento");
    console.log('elementooo');
    console.log(elem);
    if(elem){
        elem.remove();
    }


  }

  render() {
    let {item,carts,home} = this.props
    return (<div className='barra'>
      <div className='specialBar'></div>
      <div style={{
          background: 'transparent',
          position: 'relative',
          zIndex: 1,
          display: 'flex'
        }} className='containerFix'>
        <Link to="/">

            {/*<h1 style={{color:'white',fontSize:30}}>Pizzarella</h1>*/}
            {<div style={{background:'var(--color-primary)'}}><img style={{
                marginTop:10,
                width: 120,
                padding: 10,
                
              }} src={Logo} alt='logo_ristorante'/></div>}
        </Link>
        <div style={{
            display: 'flex',
            marginLeft: 'auto',
            marginTop: 8
          }}>
          <div className='buttonActionBar ' style={{
              display: 'inline-table',
              fontWeight: 300
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" className='colorp' width="16" height="16" viewBox="0 0 24 24"><path d="M4.558 7l4.701-4.702c.199-.198.46-.298.721-.298.613 0 1.02.505 1.02 1.029 0 .25-.092.504-.299.711l-3.26 3.26h-2.883zm12.001 0h2.883l-4.702-4.702c-.198-.198-.459-.298-.72-.298-.613 0-1.02.505-1.02 1.029 0 .25.092.504.299.711l3.26 3.26zm7.441 2v2h-.642c-.534 0-1.022.305-1.257.786l-4.101 10.214h-12l-4.101-10.216c-.234-.48-.722-.784-1.256-.784h-.643v-2h24zm-15 4c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.447-1-1-1s-1 .448-1 1v5c0 .552.447 1 1 1s1-.448 1-1v-5z"/></svg>
            € {subTotal(carts)}</div>
          {
            this.props.auth
              ? (<Link to="/menu">
                <div className='buttonActionBar' style={{
                    display: 'inline-table',
                    fontWeight: 300
                  }}>Menu</div>
              </Link>)
              : (<div className='buttonActionBar' style={{
                  display: 'inline-table',
                  fontWeight: 300
                }}>
                <Link to="/signin">Log in</Link>
              </div>)
          }

        </div>
      </div>
      {home ? (
        <div>
        <div className='container restaurant'>
          <div style={item && item.img
              ? {
                backgroundImage: 'url(' + item.img + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }
              : {}} className='containerFix grid02'>

            <div style={{
                padding: 10
              }}></div>
          </div>
        </div>
        <div className='containerFix' style={{
            background: 'transparent',
            padding: 10,
            marginTop: 10
          }}>

          <h2 style={{
              marginTop: 0,
              color: '#828585'
            }}>{
              item
                ? item.nome
                : null
            }</h2>
          <p style={{
              marginTop: 10,
              color: '#828585',
              fontSize: 16
            }}>{
              item
                ? item.desc
                : null
            }</p>
            {this.props.boxOrder ? (
              <div className='boxService' style={{
                display: 'flex'
              }}>
              {
  
                item && item.take_away
                  ? <div onClick={() => this.props.actionButton()} style={{
                        background: carts && carts.type == 'take_away'
                          ? '#d6d6d6'
                          : null,
                        opacity: carts && carts.type == 'take_away'
                          ? 1
                          : 0.7
                      }}>
                      <p style={{
                          color: '#828585',
                          fontSize: 20
                        }}><img style={{
                        height: 40
                      }} src="https://img.icons8.com/cotton/100/000000/take-away-food.png"/></p>
                      <p>Asporto</p>
                    </div>
                  : null
              }
              {
                item && item.delivery
                  ? <div onClick={() => this.props.actionButton()} style={{
                        background: carts && carts.type == 'delivery'
                          ? '#d6d6d6'
                          : null,
                        opacity: carts && carts.type == 'delivery'
                          ? 1
                          : 0.7
                      }}>
                      <p style={{
                          color: '#828585',
                          fontSize: 20
                        }}><img style={{
                        height: 40
                      }} src="https://img.icons8.com/cotton/64/000000/delivery-scooter.png"/></p>
                      <p>Delivery</p>
                    </div>
                  : null
              }
  
            </div>
            ) : null}
          
        </div>
      </div>
      ) : null}


      <div></div>
    </div>)
  }
}

//const condition = authUser => !!authUser;

export default Footer
