import React, { Component } from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapGL from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'
import DeckGL, { GeoJsonLayer } from "deck.gl";
import "./fix.css";




class Example extends Component {


  constructor(props) {
    super(props);


    if(this.props.value){
      this.state = {
        radius:1000,
        viewport: {
          latitude: this.props.value.coordinates[1],
          longitude: this.props.value.coordinates[0],
          zoom: this.props.radius ? 12 : 14
        },
        eventT:null,
        searchResultLayer: new GeoJsonLayer({
          id: "search-result",
          data: this.props.value,
          getFillColor: [255, 0, 0, 128],
          getRadius: this.props.radius ? 1000 : 50,
        })
      };
    }else {
      this.state = {
        radius:1000,
        viewport: {
          latitude: 37.7577,
          longitude: -122.4376,
          zoom: 8
        },
        eventT:null,
        searchResultLayer: null
      };
    }


  }


  mapRef = React.createRef()

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  };

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };
    viewport.zoom=12;
    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    //console.log(event.result);

    //let {viewport} = this.state
    this.setState({
      eventT:event,
      searchResultLayer: new GeoJsonLayer({
        id: "search-result",
        data: event.result.geometry,
        getFillColor: [255, 0, 0, 128],
        getRadius: this.props.radius ? 1000 : 100,
      }),
      objAdr:event.result
    });
    this.props.callback(event.result);
  };

  cangeRaggio(value){
    let { eventT ,radius} = this.state;
    this.setState({
      eventT:eventT,
      searchResultLayer: new GeoJsonLayer({
        id: "search-result",
        data: eventT.result.geometry,
        getFillColor: [255, 0, 0, 128],
        getRadius: Number(radius)
      }),
    });
  }


  render() {
    const { viewport, searchResultLayer } = this.state;
    return (
      <div style={{ height: "250px" }}>
        {this.props.radius ? (
          <div style={{marginBottom:10}}>
            <input onChange={(x)=>this.setState({radius:x.target.value})}  type='number' />
            <button onClick={()=>this.cangeRaggio()}>c</button>
          </div>
        ) : null}

        <MapGL
          ref={this.mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={this.handleViewportChange}
          mapboxApiAccessToken={'pk.eyJ1IjoiY2Fpbm9lcmFhYmVsZWUiLCJhIjoiY2s5ZnFydXViMGUzMDNkcW10dmQ1Nmd5biJ9.gLIh8KezgE2RKmMfveIWCw'}
        >
          <Geocoder
            placeholder={'Inserisci indirizzo'}
            mapRef={this.mapRef}
            onResult={this.handleOnResult}
            onViewportChange={this.handleGeocoderViewportChange}
            mapboxApiAccessToken={'pk.eyJ1IjoiY2Fpbm9lcmFhYmVsZWUiLCJhIjoiY2s5ZnFydXViMGUzMDNkcW10dmQ1Nmd5biJ9.gLIh8KezgE2RKmMfveIWCw'}
            collapsed={true}
            minLength={5}
            language={'it'}
            position="top-left"
          />
          <DeckGL {...viewport} layers={[searchResultLayer]} />
        </MapGL>
        <div>
          {/*<p><strong>{this.state.objAdr ? this.state.objAdr.place_name : '' }</strong></p>*/}

        </div>

      </div>
    )
  }
}

export default Example
