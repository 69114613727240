import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import API from "../../util/Api.js"
import SignUp from "../SignUp"
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Footer from '../Footer';
import Logo from '../img/logoRis.jpeg';
import Header from '../Header';

const SignInPage = () => (
            <div>
              <SignInForm />
            </div>


    /*<SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordForgetLink />*/


);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  auth:null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

      this.setState({carts:res.data});
    });
  }

  CreatecartS(){
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{

      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }

  componentDidMount() {
    window.scrollTo(0,0);
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  subTotal(){
  	let {carts} = this.state;

  	let subTotal = 0;
  	if(carts){
  		carts.items.forEach((x,i)=>{

  			subTotal = subTotal + (x.qty * x.prezzo);
  		})
  	}

  	return subTotal
  }

  render() {
    const {auth, email, password, error ,item,carts } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div>
        <Header auth={auth} item={item}  carts={carts} />
        <div className=''>
          <div className=''></div>
          
          <div  className='gridSin container restaurant' >
            <div style={{marginTop:40, textAlign: 'center',padding:20, background:'#fff',maxWidth: 600,marginLeft: 'auto',marginRight:'auto',width:'80%'}}>
              <h2>Accedi</h2>
              <form onSubmit={this.onSubmit}>
                <div>
                  <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
                    <label>Email</label>
                  </div>
                  <input
                    style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                  />
                </div>
                <div>
                  <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
                    <label>Password</label>
                  </div>
                  <input
                    style={{background:'#fff', fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <div style={{maxWidth:400,marginLeft:'auto',marginRight:'auto',marginTop:20}}><button
                   style={{marginLeft:0}}
                  className='buttonAction'
                  disabled={isInvalid} type="submit">
                  Sign In
                </button></div>

                {error && <p>{error.message}</p>}
              </form>

            </div>
            <div style={{marginTop:40, textAlign: 'center',padding:20, background:'#fff',maxWidth: 600,marginLeft: 'auto',marginRight:'auto'}}>
                <h2>Registrati</h2>
                  <SignUp />
              </div>


          </div>
        </div>
        <div className='footr'>
          <Footer />
				</div>
      </div>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {

        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
