import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import asyncComponent from "../AsyncComponent";

import withTracker from './withTracker'
import HomePagePages from '../Home';
import Delivery from '../Delivery';
import MenuDig from '../MenuHome';
import checkOutPage from '../CheckOut';
import menuPage from '../Menu';
import Privacy from '../Privacy';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import * as ROUTES from '../../constants/routes';


import { withAuthentication } from '../Session';
import ReactGA from 'react-ga';

const trackingId = "UA-167442651-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

/*
const HomePagePages = asyncComponent(() => import("../Home"));
const checkOutPage = asyncComponent(() => import("../CheckOut"));
const menuPage = asyncComponent(() => import("../Menu"));
const SignUpPage = asyncComponent(() => import("../SignUp"));
const SignInPage = asyncComponent(() => import("../SignIn"));*/



const App = () => (
  <Router>
    <div>
      <Route path={ROUTES.SIGN_UP} component={withTracker(SignUpPage)} />
      <Route path={ROUTES.SIGN_IN} component={withTracker(SignInPage)} />
      <Route exact path={ROUTES.LANDING} component={withTracker(MenuDig)} />
      <Route exact path={ROUTES.ORDER} component={withTracker(HomePagePages)} />
      <Route exact path={ROUTES.DELIVERY} component={withTracker(Delivery)} />
      <Route exact path={ROUTES.CHECKOUT} component={withTracker(checkOutPage)} />
      <Route exact path={ROUTES.MENU} component={withTracker(menuPage)} />
      <Route exact path={ROUTES.PRIVACY} component={withTracker(Privacy)} />
    </div>
  </Router>
);

export default withAuthentication(App);
